import React, {Component} from 'react'
import { Player, ControlBar, BigPlayButton } from 'video-react';
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import 'video-react/dist/video-react.css';

class VideoPlayer extends Component {
    constructor (props,context) {
        super(props,context);
        this.state = {}
        console.log(this.props);
    }

    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state) {
        this.setState({
            player: state
        });

    }

    render() {
        return(
            <div>
                <Helmet>
                    {/* <link
                        rel="stylesheet"
                        href="https://video-react.github.io/assets/video-react.css"
                    />     */}
                    <meta property="og:type" content="video.other" />
                    <meta property="og:video:type" content="video/mp4" />

                    <meta property="og:video" content={this.props.url} />
                    <meta property="og:video:secure_url" content={this.props.url} />

                    {/* <meta property="og:title" content="Message from Michael" /> */}
                    <meta property="og:video:height" content="1280" />
                    <meta property="og:video:width" content="720" />
                    {/* <meta property="og:description" content="A Message from Michael Stipe" /> 
                    <meta name="twitter:title" content="A Message from Michael" />
                    <meta name="twitter:description" content="A message from Michael Stipe, directly" /> */}
                    <meta name="twitter:card" content="player" />
                    <meta name="twitter:site" content="MichaelStipe.com" />
                    <meta name="twitter:player" content="https://michaelstipe.com/video_embed.html" />
                    <meta name="twitter:player:width" content="720" />
	                <meta name="twitter:player:height" content="1280" />                    
                    <meta name="twitter:player:stream" content={this.props.url} />
                    <meta name="twitter:player:stream:content_type" content="video/mp4;"/>

                </Helmet>
                

                <Player 
                    ref={player => { this.player = player}}
                    autoPlay
                    fluid={false}
                    playsInline
                    width={320}
                >
                    <source src={this.props.url} />
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={true} />    
                </Player>
                <pre>
                    {/* {this.state.player['duration']} */}
                    {/* {JSON.stringify(this.state.player, null, 2)} */}
                </pre>
            </div>

        );
    }
}

VideoPlayer.propTypes = {
    url: PropTypes.string,
    location: PropTypes.object,
}

export default VideoPlayer