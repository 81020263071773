import React from 'react'
import { Link } from 'gatsby'
import VideoPlayer from '../components/VideoPlayer'
import ReactPlayer from 'react-player'
import Layout from '../components/layout'
import Video from '../components/Video'
import Helmet from 'react-helmet'
import { FacebookShareButton, TwitterShareButton, TwitterIcon, FacebookIcon } from 'react-share'
import SEO from '../components/SEO'

class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.state = {
      loading: 'is-loading'
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <div>
        {/* <Helmet>
          <meta name="twitter:site" content="MichaelStipe.com" />

          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:site_name" content="MichaelStipe.com" />
          <meta property="og:image" content={this.props.location.origin + cover} />
          <meta property="twitter:image" content={this.props.location.origin + cover} />
          

        </Helmet> */}
        <Layout location={this.props.location}>
          <SEO
            title="Message from Michael Stipe"
            description="A video message from Michael Stipe. Directly."
            pathname={this.props.location.pathname}
          />
          <div className={`body ${this.state.loading}`}>
            <div id="wrapper" className="video-wrapper">
              <h2>New from Michael</h2>
              <div className="body">
                <div align="center">
                {/* <VideoPlayer location={this.props.location} url="https://download.michaelstipe.com/supertuesday.mov" /> */}
                <ReactPlayer url='https://www.youtube.com/watch?v=MYgpEcXf2S4' />
                <h3>NO TIME FOR LOVE LIKE NOW<br/>First take! A new song with Aaron Dessner.<br/>This is the demo track.<br/>Echoing Love xxx Michael</h3>
                  {/* <FacebookShareButton
                    hashtag='#drivetotheocean'
                    url={this.props.location.href}
                    className="btn"
                    resetButtonStyle={false}
                  >
                    Share Here
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={this.props.location.href}
                    title="Message from Michael Stipe"
                    resetButtonStyle={false}
                  >
                    Share There
                  </TwitterShareButton> */}
                  </div>
              </div>

              <Link to="/">Go back to the homepage</Link>

            </div>

            <div className={`body video-background ${this.state.loading} `}>
              <div id="bg" className="video-foreground" >
                {/* <video autoPlay muted loop playsInline id="loopVideo">
                <source src={loop_video} type="video/mp4" />
              </video> */}
                <Video />

              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
};

export default SecondPage
